import React from "react";
import {Link} from "gatsby";

import Layout from "../../components/layout";
import {GettingStartedSidebar as Sidebar} from "../../components/sidebar";
import {GettingStartedBreadcrumb as Breadcrumb} from "../../components/breadcrumb";
import {AskHowToInfoAlert} from "../../components/alert";
import {CodeSnippet} from "../../components/snippet";
import {ApiLink} from "../../components/link";

const pageTitle = "Get Output Parameters";

const Page = () => (
    <Layout pageTitle={pageTitle}
            sidebarNav={<Sidebar/>}
            mobileNav={<Breadcrumb activeName={pageTitle}/>}
    >
        <p>
            If stored procedure does not generate result set(s) you can
            reach output parameters immediately after calling
            {' '}<ApiLink>SACommand::Execute</ApiLink>:
        </p>
        <CodeSnippet>{`printf("%d\\n", cmd.Param(_TSA("nOutput")).asInt32());`}</CodeSnippet>

        <p>
            You can use {' '}<ApiLink>SAParam</ApiLink>{' '} () operators
            for quick accessing values. In this case you can
            process the result as shown below:
        </p>
        <CodeSnippet>{`
int out_param = cmd.Param(_TSA("nOutput"));
printf("%d\\n", out_param);
            `.trim()}
        </CodeSnippet>

        <p>
            After the statement has been executed, SQLAPI++ stores the
            returned values of output parameters in the
            {' '}<ApiLink>SAParam</ApiLink>{' '} objects bound to those
            parameters. On some servers these returned values are not guaranteed to
            be set until all results returned by the procedure have been fetched
            (using {' '}<ApiLink>SACommand::FetchNext</ApiLink>{' '}
            method). See {' '}<Link to="/ApiDoc/servers/">Server specific information</Link>{' '} on output
            parameters availability on different DBMSs.
        </p>
        <p>
            For processing result set(s), if any, see
            {' '}<Link to="/HowTo/fetch/">Fetch result set(s)</Link>.
        </p>
        <p>
            If you call a function SQLAPI++ library automatically creates
            {' '}<ApiLink>SAParam</ApiLink>{' '} object to represent the
            function return value. You can refer to this {' '}<ApiLink>SAParam</ApiLink>{' '}
            object using SQLAPI++ predefined name {' '}<code>"RETURN_VALUE"</code>.
        </p>
        <p>
            Returning Long, BLob and CLob data can have some differences
            (if you need piecewise operations) from other data types.
            See {' '}<Link to="/HowTo/blobs/">Handle Long/CLob/BLob</Link>{' '} to get more information.
        </p>

        <AskHowToInfoAlert/>
    </Layout>
);

export default Page;
